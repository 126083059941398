<template>
  <!-- 资料库>新增资料 -->
  <div class="dict">
    <el-page-header
      class="page-header"
      @back="goBack"
      :content="edit ? '资料管理/编辑资料' : '资料管理/新增资料'"
    >
      <template slot="title"> <i class="el-icon-arrow-left"></i>返回 </template>
    </el-page-header>
    <el-form
      ref="ruleForm"
      style="width: 80%"
      :model="form"
      :rules="rules"
      :label-width="labelWidth"
    >
      <el-form-item label="上级目录：" prop="directoryId">
        <el-cascader
          :show-all-levels="false"
          style="width: 100%"
          v-model="form.directoryId"
          :options="parentList"
          :props="treeProps"
        ></el-cascader>
      </el-form-item>

      <el-form-item label="文件名称：" prop="fileName">
        <el-input v-model="form.fileName"></el-input>
      </el-form-item>
      <el-form-item label="版本号：" type="number" prop="versionNumber">
        <!-- :disabled="!edit && !$route.query.isHuiGun" -->
        <el-col :span="23">
          <el-input
            type="number"
            min="1"
            v-model="form.versionNumber"
          ></el-input>
        </el-col>
        <el-col :span="1">
          <el-popover
            placement="top"
            title=""
            width="200"
            trigger="hover"
            content="大版本建议修改小数点前数字、小版本建议修改小数点后数字。"
          >
            <i slot="reference" class="el-icon-warning-outline"></i>
          </el-popover>
        </el-col>
      </el-form-item>
      <el-form-item label="文件摘要：" prop="summary">
        <el-input type="textarea" rows="4" v-model="form.summary"></el-input>
      </el-form-item>
      <!-- <el-form-item label="排序：" prop="sort">
        <el-input v-model="form.sort" @input="form.sort = form.sort.length === 1 ? form.sort.replace(/[^0-9]/g, '') : form.sort.replace(/\D/g, '')" ></el-input>
      </el-form-item> -->
      <el-form-item label="权限：" prop="authority">
        <el-checkbox-group @change="changeAuthority" v-model="form.authority">
          <el-checkbox label="1" name="type">在线查看</el-checkbox>
          <el-checkbox label="2" name="type">下载为PDF</el-checkbox>
          <el-checkbox label="3" name="type">下载为原文件</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="附件类型：" prop="type">
        <el-radio-group v-model="type" @change="changeType">
          <el-radio label="1">文件</el-radio>
          <el-radio label="2">视频</el-radio>
          <el-radio label="3">图片</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="上传附件：" prop="uploadList" class="is-required">
        <el-upload
          class="upload-demo"
          :disabled="loadingUpload"
          action="/webForm/dataDirectory/data-attachment/uploadFile"
          :on-success="handleSuccess"
          :on-remove="handleRemove"
          :before-upload="beforeAvatarUpload"
          :file-list="uploadList"
          :headers="uploadHeader"
        >
          <el-button
            size="small"
            :loading="loadingUpload"
            :disabled="loadingUpload"
            type="primary"
            >点击上传</el-button
          >
          <!-- <div slot="tip" class="el-upload__tip">请上传文件</div> -->
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button @click="goBack" class="reset">返回</el-button>
        <el-button
          type="primary"
          :disabled="uploading"
          :loading="uploading"
          @click="saveManage('ruleForm')"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { getToken } from "@/utils/auth";
import { mapGetters, mapState } from "vuex";
import { testFun } from "@/utils/validate";
export default {
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持数字"));
      } else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
        callback(new Error("只支持数字，首位不能是0"));
      } else {
        callback();
      }
    };
    return {
      form: {
        authority: ["1"],
        directoryId: null,
        summary: "",
        fileName: "",
        type: null,
        versionNumber: "1.0",
        sort: "",
      },
      edit: false,
      type: null,
      uploading: false,
      directoryList: [],
      attachmentList: [],
      rules: {
        directoryId: [
          { required: true, message: "请选择上级目录", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        summary: [
          { required: true, message: "请输入文件摘要", trigger: "blur" },
        ],
        versionNumber: [
          { required: true, message: "请输入版本号", trigger: "blur" },
        ],
        fileName: [
          { required: true, message: "请输入文件名称", trigger: "blur" },
        ],
        authority: [{ required: true, message: "请选择权限", trigger: "blur" }],
        type: [{ required: true, message: "请选择附件类型", trigger: "blur" }],
        // uploadList: [{ required: true, message: "请上传附件", trigger: "change" }],
      },
      uploadList: [],
      treeProps: {
        value: "id",
        label: "directoryName",
        children: "child",
      },
      parentList: [],
      uploadHeader: { token: this.getToken() },
      loadingUpload: false,
      metaTitle: "",
      labelWidth: "120px",
      submitCount: 0,
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     if (to.query.id) {
  //       vm.getManageInfo(to.query.id);
  //     }
  //   });
  // },
  mounted() {
    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "80px";
      } else {
        this.labelWidth = (120 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
    if (window.innerWidth < 1300) {
      this.labelWidth = "80px";
    } else {
      this.labelWidth = (120 / 1920) * window.innerWidth + "px";
    }
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isUpdate = val.indexOf("dict:update") != -1;
          this.isAdd = val.indexOf("dict:save") != -1;
          this.isDelete = val.indexOf("dict:delete") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    this.getThirdDirectoryList();
    if(this.$route.query.directoryId){
        this.form.directoryId=parseInt(this.$route.query.directoryId);
    }
    if (this.$route.query.id && this.$route.query.isHuiGun) {
      this.getFileInfoByVersionId(this.$route.query.id);
      
    } else if (this.$route.query.id) {
      this.getManageInfo(this.$route.query.id);
      this.metaTitle = "编辑资料";
      let chooseMenuInfoList = sessionStorage.getItem("chooseMenuInfoList")
        ? JSON.parse(sessionStorage.getItem("chooseMenuInfoList"))
        : null;
      if (chooseMenuInfoList) {
        chooseMenuInfoList.map((item) => {
          if (item.path == this.$route.path) {
            item.meta.title = this.metaTitle;
          }
        });
        let breadCrumbList = JSON.parse(JSON.stringify(chooseMenuInfoList));
        breadCrumbList.shift();
        this.$store.commit("common/getBreadcrumbList", breadCrumbList || []);
      }
      // console.log(breadCrumbList)
    }
    
    // this.getSecDirectory()
  },
  methods: {
    getToken,
    getPermissionButton,
    isHasPermission,
    getThirdDirectoryList() {
      this.$api.getThirdDirectory().then((res) => {
        if (res.data.code == 0) {
          // this.directoryList = res.data.data;
          this.parentList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getSecDirectory() {
      this.$api.getSecDirectory().then((res) => {
        if (res.data.code == "0") {
          this.parentList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    getFileInfoByVersionId(fileId) {
      this.$api.getFileInfoByVersionId({ id: fileId }).then((res) => {
        if (res.data.code == "0" && res.data.data) {
          this.form = res.data.data;
          this.type = this.form.type;
          this.form.authority = this.form.authority.split(",");
          if (this.form.dataAttachmentList) {
            this.uploadList = this.form.dataAttachmentList;
            this.uploadList.forEach((item) => {
              item.name = item.attachmentName;
            });
            // console.log(this.uploadList)
          }
        } else {
          this.$message.error(res.data.msg || "该数据不存在");
        }
      });
    },
    getManageInfo(id) {
      let params = {
        id: id || this.id,
      };
      console.log(params);
      this.$api.getFileInfo(params).then((res) => {
        if (res.data.code == 0 && res.data.data) {
          this.form = res.data.data;
          this.edit = true;
          this.type = this.form.type;
          this.form.authority = this.form.authority.split(",");
          if (this.form.dataAttachmentList) {
            this.uploadList = this.form.dataAttachmentList;
            this.uploadList.forEach((item) => {
              item.name = item.attachmentName;
            });
            console.log(this.uploadList);
          }
        } else {
          this.$message.error("该数据不存在");
          // this.goBack();
        }
      });
    },
    // 修改权限
    changeAuthority(val) {
      if (val.indexOf("3") >= 0) {
        this.form.authority = ["1", "2", "3"];
      } else if (val.indexOf("2") >= 0) {
        this.form.authority = ["1", "2"];
      }
      this.$forceUpdate();
    },
    changeType(type) {
      // this.form.type = type
      if (this.form.type) {
        if (this.uploadList.length) {
          this.$confirm(
            "切换附件上传类型会清空已上传的文件, 是否继续?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              customClass: "addChild",
            }
          )
            .then(() => {
              this.uploadList = [];
              this.form.type = type;
            })
            .catch(() => {
              this.type = this.form.type;
            });
        } else {
          this.form.type = type;
        }
      } else {
        this.form.type = type;
      }
    },

    saveManage(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          testFun(this.saveManageApi);
        } else {
          // this.$message.error("请完善信息");
          return false;
        }
      });
    },

    saveManageApi() {
      if (!this.uploadList.length) {
        this.$message.error("至少上传一个附件");
      } else {
        let parmas = JSON.parse(JSON.stringify(this.form));
        parmas.size = 0;
        parmas.dataAttachmentList = [];
        if (typeof parmas.directoryId == "object") {
          parmas.directoryId =
            parmas.directoryId[parmas.directoryId.length - 1];
        }
        console.log(this.uploadList);
        this.uploadList.forEach((item) => {
          let temp;
          if (item.url.fileUrl) {
            console.log(item.size);
            temp = {
              size: item.size,
              attachmentName: item.name.split(".")[0],
              attachmentType: item.fileType,
              url: item.url.fileUrl,
              // pdfUrl,pdfUrl
              pdfUrl:
                item.name.split(".")[1].toLowerCase() == "pdf"
                  ? item.url.fileUrl
                  : item.url.pdfUrl || null,
              type: item.type,
            };
          } else {
            temp = {
              size: item.size,
              attachmentName: item.name.split(".")[0],
              attachmentType: item.fileType,
              url: item.url,
              // pdfUrl,pdfUrl
              pdfUrl: item.pdfUrl,
              type: item.type,
            };
          }
          parmas.size += Number(item.size);
          parmas.dataAttachmentList.push(temp);
        });
        parmas.authority = parmas.authority.join(",");
        console.log(parmas);
        this.$api.saveOrUpdateFileInfo(parmas).then((res) => {
          //  this.submitCount = 0;
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.goBack();
          } else {
            this.$message.error(res.data.msg || "保存失败");
          }
        });
      }
    },

    handleSuccess(res, file, fileList) {
      this.loadingUpload = false;
      console.log(res, file, fileList);
      this.uploading = false;
      if (res.code != 0) {
        /*上传失败 移除列表*/
        this.$message.error(res.msg);
        fileList.forEach((item, index) => {
          if (item.uid == file.uid) {
            fileList.splice(index, 1);
          }
        });
        return;
      }
      if (!this.form.fileName) {
        this.form.fileName = file.name.split(".")[0];
      }
      this.uploadList.push({
        name: file.name,
        fileType: file.name.split(".")[file.name.split(".").length - 1],
        uid: file.uid,
        type: this.type,
        size: file.size,
        url: res.data,
      });
    },
    handleRemove(file, fileList) {
      console.log(file);
      let uid = file.uid || file.id;
      this.uploadList.forEach((item, index) => {
        if (item.uid == uid || item.id == uid) {
          this.uploadList.splice(index, 1);
        }
      });
      console.log(this.uploadList);
    },
    checkFileType(name) {
      this.loadingUpload = true;
      let regx = null;
      let type = name.split(".")[name.split(".").length - 1];
      if (this.type == 1) {
        regx = /doc|DOC|docx|DOCX|PPT|ppt|PPTX|pptx|xls|XLS|xlsx|XLSX|pdf|PDF/;
        if (!regx.test(type)) {
          this.$message.error(
            "文件格式有误,只支持doc,docx,ppt,pptx,xls,xlsx,pdf"
          );
          this.loadingUpload = false;
          return false;
        }
        return true;
      } else if (this.type == 2) {
        regx = /MP4|mp4|mov|MOV|FLV|flv|avi|AVI|mpg|MPG/;
        if (!regx.test(type)) {
          this.$message.error("视频格式有误,支持mp4,mov,flv,avi,mpg");
          this.loadingUpload = false;
          return false;
        }
        return true;
      } else if (this.type == 3) {
        regx = /JPG|jpg|PNG|png|BMP|bmp/;
        if (!regx.test(type)) {
          this.$message.error("图片格式有误,支持jpg,png,bmp");
          this.loadingUpload = false;
          return false;
        }
        return true;
      } else {
        this.$message.error("请先选择附件类型");
        this.loadingUpload = false;
      }
      // this.uploading = false;
      return false;
    },
    beforeAvatarUpload(file) {
      if (!this.type) {
        this.$message.error("请先选择附件类型");
        return false;
      }
      this.loadingUpload = true;
      if (this.checkFileType(file.name)) {
        return file;
      }
      return false;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", (e) => {
      console.log(e);
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "80px";
      } else {
        this.labelWidth = (120 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
  },
};
</script>

<style lang="scss" scoped>
.dict {
  background-color: #ffffff;
  height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .tableList {
    height: calc(100% - 62px);
  }
}
/deep/.page-header {
  margin-bottom: 20px;
}
.el-icon-warning-outline {
  font-size: 20px;
  text-align: center;
  color: #999;
  margin-left: 10px;
}
</style>
<style>
.addChild .el-button {
  width: 96px;
  /* height: 38px; */
}
textarea {
  font-family: inherit;
}
</style>
